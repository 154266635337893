export function loadSwiper(){
    new Swiper('.banner .swiper-container', {
        spaceBetween: 0,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.banner .swiper-pagination',
            clickable: true,
        },
    });


    new Swiper('.a .hd .swiper-container', {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.a .hd .swiper-button-next',
            prevEl: '.a .hd .swiper-button-prev',
        },

    });

    new Swiper('.a .bd .swiper-container', {
        spaceBetween: 0,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.a .bd .swiper-pagination',
            clickable: true,
        },
    });
}
