<template>
	<div class="body">
		<keep-alive>
      <div class="box">
        <H5Header  />
        <div class="swiperbox">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="item in banner" :key="item.id">
              <a href="javascript:;"><img  :src="$baseURL + item.imgUrl"></a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="titlebox">
          <el-carousel height="40px" arrow="never" direction="vertical" :autoplay="true">
            <el-carousel-item v-for="item in swiper" :key="item.id">
              <h1 class="title">{{item.title}}</h1>
            </el-carousel-item>
          </el-carousel>

        </div>
        <div class="swiperbox2">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="item in cards" :key="item.id" class="el-swiper">
              <a href="javascript:;" @click="toDetail(item.id,'工作动态')"><img  :src="$baseURL + item.imgUrl"></a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <template v-for="(item,num) in allData" >
          <div class="listbox top" :key="item.id" v-if="item.articles && item.articles.length>0">
            <div class="navtitle">
              <div class="itemtitle">{{item.name}}</div>
              <a href="javascript:;" @click="toMore(item.id)"><button class="more">更多</button></a>
            </div>
            <hr>
            <ol class="list" >
              <li class="listitem" v-for="val in  item.articles" :key="val.id+'two'">
                <div class="circle"></div>
                <div class="itemtext maxline1"><a href="javascript:;" @click="toDetail(val.id,item.name)">{{val.title}}</a></div>
                <div class="date">{{val.publishTime | filter}}</div>
              </li>
            </ol>
          </div>
          <template  v-if="$store.state.stations.id=='1004'">
            <div class="banner1"   :key="item.id+'three'" v-if="item.articles && item.articles.length>0 && [1,0].includes(num)">
              <img src="./image/zj0.jpg" v-if="num == 0" @click="toMore(453)" />
              <img src="./image/zj1.png" v-else-if="num == 1" @click="toMore(444)" />
            </div>
          </template>
          <template v-else>
            <div class="banner" style="height: 72.5px;"  :key="item.id+'three'" v-if="item.articles && item.articles.length>0">
              <img src="./image/baner04.png" />
            </div>
          </template>

        </template>

        <!--			<div class="banner">-->
        <!--				<img src="./image/baner02.png" />-->
        <!--			</div>-->
        <!--			<div class="banner baner03">-->
        <!--				<img src="./image/baner03.png" />-->
        <!--			</div>-->
        <H5Footer />
      </div>
    </keep-alive>
    <transition name="plus-icon">
		<div class="layer" v-if="layer" >
			<hr class="layerline" />
			<div class="layerbox">
				<div v-for="item in navs" :key="item.id">
					<div class="layeritem">
						<a href="h5view"><div class="layeritemtitle">{{item.name}}</div></a>
            <i class="el-icon-caret-right"></i>
					</div>
				</div>
			</div>
		</div>
    </transition>
	</div>
</template>

<script>
import H5Header from "./components/header"
import H5Footer from "./components/footer"
import {loadSwiper} from "@/libs";

	export default {
    components:{
      H5Header,
      H5Footer
    },
    filters:{
      filter(x){
        return x.split(' ')[0]
      }
    },
		data() {
			return {
				layer: false,
				zjcx: ['证件号', '企业名称', '证件类别', ],
				navs: [],
        banner:[],
        swiper:[],
        cards:[],
        notice:[],
        hydt:[],
        gzdt:[],
        allData:[],
        advImg:[
            '/image/baner04.png',
            '/image/baner01.png',
            '','','',''
        ]
			}
		},
    mounted() {
      console.log(this.$store.state.stations.id,"站点")
      this.getBanner()
      this.getSwiper()
      this.getCards()
      // this.getNotice()
      // this.gethydt()
      // this.getgzdt();
      this.getIndexColumn()

    },

    methods: {
      toDetail(id,name){
        this.$router.push({path:'/h5view',query:{articleId:id,name:name}})
      },
      toMore(cid){
        this.$router.push({path:'/h5list',query:{pid:cid}})
      },
      //获取轮播数据
      async getBanner(){
        let res = await this.$api.getTopBanner({type:1});
        this.banner = res.data.data;

      },
      async getSwiper(){
        let res = await this.$api.getIndexCharList()
        this.swiper = res.data.data;
      },
      async getCards(){
        let res = await this.$api.getIndexImgList()
        this.cards = res.data.data.slice(0,4)
        setTimeout(()=>{
          loadSwiper();
        },100)
      },
      async getNotice(){
        let res = await this.$api.getColumn({
          columnName:'通知公告'
        })
        this.notice = res.data.data[0];
      },
      async getIndexColumn(){
        let res = await this.$api.getIndexH5Column();

        this.allData = res.data.data.flat();
        console.log('获取全部的内容为',this.allData);
      },
      async gethydt(){
        let res = await this.$api.getColumn({columnName:'行业动态'});
        this.hydt = res.data.data;
      },
      async getgzdt(){
        let res = await this.$api.getColumn({columnName:'工作动态'});
        this.gzdt = res.data.data;
        console.log(this.gzdt)
      },
		}
	}
</script>

<style>
	.body {
		width: 100%;
		/* height: 100%; */
		overflow: scroll;
	}
  .banner img{
    height: auto !important;
  }
	.box {
		margin: auto auto;
		/*width: 320px;*/
		background: #FFFFFF;
    width: 100%;
    overflow: hidden;
	}

	.header {
		/*margin: 10px auto;*/
		/*width: 320px;*/
		height: 50px;
		display: flex;
		justify-content: space-around;
	}

	.logo {
		margin-top: 10px;
		width: 250px;
		height: 33.52px;
	}

	.menu {
		margin-top: 15px;
		width: 22px;
		height: 15.5px;
	}

	.swiperbox {
		/*width: 320px;*/
		height: auto;
		overflow: hidden;
	}
  .swiperbox img{
    /*width: 320px;*/
    height: auto;
    overflow: hidden;
  }

  .swiperbox .el-carousel__container{
    height: 101px;
    width: 100%;
  }

	.picbox {
		/*width: 960px;*/
		animation: switch 5s ease-out infinite;
	}

	.swiperpic {
		/* border: solid 2px; */
		float: left;
		/*width: 320px;*/
    width: 100vw;
		height: 260px;
	}

	.titlebox {
		/*width: 320px;*/
		height: 40px;

	}

	.title {
		/*width: 320px;*/
		line-height: 40px;
		text-align: center;
		font-size: 15px;
		font-weight: 600;
		font-family: Microsoft YaHei;
    padding:0 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	}

	.swiperbox2 {
		/*width: 320px;*/
		height: 165px;
		overflow: hidden;
    width: 100%;
    padding: 0 20px;
	}
  .swiperbox2 .el-carousel__container{
    height: 165px;
    width: 100%;
  }
  .swiperbox2 img{
    /*width: 320px;*/
    height: 165px;
    overflow: hidden;
    width: 100%;
  }


	.swiperpic2 {
		margin: 7px 10px;
		width: 100%;
		height: 165px;
	}

	.tips {
		margin: -37px 10px;
		width: 300px;
		height: 30px;
		background: #000000;
		opacity: 0.5;
		color: #FFFFFF;
		line-height: 30px;
		padding-left: 10px;
	}

	.listbox {
		/*width: 320px;*/
		margin: 0px auto;
    padding: 0 20px;
	}

	.top {
		margin-top: 10px;
	}

	.navtitle {
		margin: auto auto;
		/*width: 300px;*/
		/*height: 40px;*/
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		box-sizing: content-box;
	}

	hr {
		margin: -3px auto;
		width: 300px;
		border: #ECF5FF 2px solid;
	}

	.itemtitle {
		font-size: 15px;
		min-width: 60px;
		font-weight: 600;
		color: #244991;
		border-bottom: #244991 3px solid;
		line-height: 40px;
	}

	.more {
		margin-top: 15px;
		width: 41px;
		height: 20px;
		border-radius: 4px;
		background-color: #f1f7fe;
		color: #666666;
	}

	.list {
		margin: 15px auto;
		/*width: 300px;*/
	}

	.listitem {
		height: 30px;
		font-size: 10px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.circle {
		margin-top: 12px;
		width: 7px;
		height: 7px;
		border: solid #8A9DC2 2px;
		border-radius: 50%;
	}

	.itemtext {
		margin-left: 5px;
		flex: 1;
		line-height: 30px;
	}

	.date {
		line-height: 30px;
		color: #999999;
	}

	.navbox {
		margin: auto auto;
		width: 300px;
		height: 68px;
		border: solid 1px #e2ecff;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.itembox {
		margin: auto auto;
		width: 50px;
		height: 50px;
		color: #e2ecff;
	}

	.login {
		margin-left: 6px;
		width: 37px;
		height: 21.5px;
	}

	.fkyj {
		margin-left: 7px;
		width: 30px;
		height: 21.5px;
	}

	.zxls {
		margin-left: 12.5px;
		width: 20px;
		height: 21.5px;
	}

	.menutitle {
		font-size: 10px;
		margin-top: 10px;
	}

	.vertical {
		margin: auto auto;
		font-size: 40px;
		background-color: #e2ecff;
		width: 2px;
		height: 40px;
	}

	.banner {
		margin-top: 20px;
		/*width: 320px;*/
		height: 90px;
	}

  .banner1 {
    margin-top: 20px;
    height: 65px;
    width: auto;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

	.baner03 {
		/*width: 320px;*/
		height: 105.5px;
		margin-top: -30px;
	}

	.hzhbbox {
		/*margin-top: -16px;*/
		/*width: 320px;*/
		background: #00337D;
		color: #FFFFFF;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.hztitle {
		margin-left: 20px;
		font-weight: 600;
		font-size: 15px;
	}

	.linebox {
		margin: 10px auto;
		width: 300px;
		height: 42.5px;
		background: #FFFFFF;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.linetext {
		margin: auto auto;
		color: #000000;
		width: 206.5px;
	}

	.arrow {
		margin: auto auto;
		width: 14px;
		height: 7px;
	}

	.qrbox {
		/*width: 320px;*/
		background: #09459B;
		padding-bottom: 20px;
		color: #FFFFFF;
    width: 100%;
	}

	.arrowtop {
		margin-left: 135px;
		width: 50px;
	}

  .qrimg{
    display: flex;
    align-items: center;
    justify-content: center;
  }
	.qrcode {
		width: 121px;
		height: 121px;

	}

	.kfdh {
		margin-bottom: 20px;
		font-size: 15px;
		margin-left: 10px;
	}

	.layer {
		position: fixed;
		top: 0;
    left: 0;
    z-index: 100;
		width: 60vw;
		background: rgba(0,0,0,0.8);
	}

	.layeritem {
		font-size: 15px;
		font-family: Microsoft YaHei;
		color: #FFFFFF;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid #f1f1f1;
	}

	.layeritemtitle {
		margin-left: 5px;
		color: #FFFFFF;
	}

	.layermore {
		font-size: 18px;
		color: #fff;
	}

	.layerline {
		margin: auto auto;
    width: 100%;
		border: solid 1px #2b2b2c;
	}

	@keyframes switch {

		0%,
		25% {
			margin-left: 0;
		}

		35%,
		60% {
			margin-left: -320px;
		}

		70%,
		100% {
			margin-left: -640px;
		}
	}
  .plus-icon-enter-active{
    transition: opacity .5s;
  }
  .plus-icon-enter{
    opacity: 0;
  }
  .plus-icon-leave-active{
    transition: opacity .5s;
  }
  .plus-icon-leave-to{
    opacity: 0;
  }

</style>
